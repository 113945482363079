<template>
  <van-popup class="datePickerVue" v-model:show="visible" position="bottom" :style="{ width: '100%' }">
    <van-datetime-picker
        v-model="currentDate"
        :formatter="formatter"
        type="date"
    >

      <div style="text-align: center;width: 100%;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold"
           slot="default">
        选择日期
      </div>

      <div @click="sureTime" slot="columns-bottom" class="sureButton">
        确定
      </div>

    </van-datetime-picker>
  </van-popup>
</template>

<script>

export default {
  name: 'datePickerVue',
  props:{
    timeStamp:{
      default:new Date().getTime()
    }
  },
  watch:{
    timeStamp:{
      handler(value) {
        this.currentDate = new Date(value)
      },
      immediate:true,
      deep:true,
    }
  },
  data() {
    return {
      currentDate: new Date(),
      visible: false
    }
  },
  mounted() {
  },
  methods: {
    sureTime: function() {
      this.$emit('sureTime', { time: this.currentDate.getTime() })
      this.visible = false;
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    showOrHidden: function(visible) {
      this.visible = visible
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.datePickerVue {

  .sureButton {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $color_primary;
    width: calc(100% - 20px);
    margin-left: 10px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    text-align: center;
  }

}

</style>
<template>
  <div class="searchDate">
    <div class="searchView">
      <div class="searchViewContent">
        <el-input size="medium" class="search"
                  prefix-icon="el-icon-search" clearable v-model="baseData.query" placeholder="请输入内容"
        >
        </el-input>
        <div class="seachButtonContent" @click="searchEvent">搜索</div>

      </div>

<!--      <div class="dateContent">-->
<!--        <svg-icon class="icon" width="20px" height="20px" name="date"></svg-icon>-->

<!--        <div class="dateString" @click="showDateVue">-->
<!--          {{ formatDayDate(baseData.timeStamp) }}-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <date-picker-vue @sureTime="sureTime" ref="datePicker" :time-stamp="baseData.timeStamp"></date-picker-vue>

  </div>
</template>

<script>
import DatePickerVue from '@/components/datePicker/datePickerVue'
export default {
  name: 'searchDate',
  components: { DatePickerVue },
  data() {
    return {
      // baseData: { query: '', timeStamp:new Date().getTime() }
      baseData: { query: '', timeStamp:1609220166000}
    }
  },
  methods: {
    showDateVue: function() {
      this.$refs['datePicker'].showOrHidden(true)
    },
    sureTime:function(res) {
      this.baseData.timeStamp = res.time
      this.searchEvent();
    },
    searchEvent: function() {
      console.log(this.baseData.timeStamp)
      this.$emit('searchEvent', { query: this.baseData })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.searchDate {
  width: 100%;
  height: 50px;

  .titleItem {
    width: 60px;
    float: left;
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
    font-size: 14px;
    color: white;

  }


  .searchView {
    width: 100%;
    height: 50px;

    .searchViewContent {
      //width: calc(100% - 140px);
      width: calc(100% - 20px);
      height: 40px;
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;

      .search {
        margin-left: 10px;
        width: 100%;
        flex-shrink: 0;
      }

      .seachButtonContent {
        flex-shrink: 0;
        color: white;
        background-image: linear-gradient(to left, $color_primary, $color_primary, #4ca6f6, #cfe8ff);
        height: 32px;
        border-radius: 16px;
        line-height: 32px;
        text-align: center;
        width: 70px;
        text-align: center;
        position: relative;
        transform: translate(7px, -34px);
        float: right;
      }


    }


    .dateContent {
      float: right;
      width: 130px;
      height: 100%;
      display: flex;
      justify-content: right;
      padding-right: 10px;

      .icon {
        width: 20px;
        height: 20px;
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);
      }

      .dateString {
        padding-left: 10px;
        color: white;
        height: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 13px;
      }


    }


  }
}

::v-deep .search .el-input__inner {
  border-radius: 20px;
}

</style>
<template>
  <div class="tabs">
    <van-tabs @click="handleClick" :style="{'--height': height}" v-model="activeNameTemp" swipeable>
      <van-tab v-for="(item) in tabsList" :title="item.label" :name="item.name">
        <slot :name="item.name"></slot>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  computed: {
    activeBarPadding() {
      if (this.itemPadding == 0) {
        return '18px'
      }
      return `${this.itemPadding}px`
    },
    padding() {
      if (this.itemPadding == 0) {
        return '0px'
      }
      return `0 ${this.itemPadding}px`
    }
  },
  watch: {
    activeName: {
      handler(value) {
        this.activeNameTemp = value
      },
      immediate: true
    }
  },
  props: {
    itemPadding: {
      default: 20
    },
    height: {
      default: 'calc(100% - 80px)'
    },
    activeName: {
      default: ''
    },
    tabsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      activeNameTemp: this.activeName
    }
  },
  methods: {
    handleClick(tab, event) {
      this.$emit('activeNameChange', { activeName: this.activeNameTemp })
    }
  }
}
</script>

<style lang="scss" scoped>

.tabs {
  height: 100%;
}

::v-deep .van-tabs__nav {
  background-color: transparent;
}

::v-deep .van-tab__text {
  color: white;
}

::v-deep .van-tabs__line {
  background-color: white;
}

::v-deep .van-tab--active {
  font-weight: bold;
  font-size: 15px;
}

::v-deep .van-tabs__content {
  height: 100%;
}

::v-deep .van-tab__pane {
  height: 100%;
}

::v-deep .van-tabs {
  height:var(--height);
}

</style>
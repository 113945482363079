<template>
  <div class="resonseHome" :style="{ 'background-image': 'url(' + bgImg + ')' }">
    <search-date @searchEvent="searchEvent"></search-date>
    <tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>
    <div class="page-container">
      <router-view :query="query"/>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/tabs/tabs'
import SearchDate from '@/components/searchDate/searchDate'

let bgImg = require('../../../../../../static/iconImage/operationCenter.png')

export default {
  name: 'resonseHome',
  components: { SearchDate, Tabs },
  data() {
    return {
      query: {
        query: '',
        timeStamp: 1609220166000,
      },
      bgImg: bgImg,
      activeTab: '/deviceView1/reponseHome/repairResponseRecord',
      tabList: [
        {
          title: '维修响应记录',
          path: '/deviceView1/reponseHome/repairResponseRecord'
        },
        {
          title: '催单响应记录',
          path: '/deviceView1/reponseHome/remindResponseRecord'
        },
        {
          title: '报修记录',
          path: '/deviceView1/reponseHome/repairRecord'
        },
        {
          title: '维修审核记录',
          path: '/deviceView1/reponseHome/checkRecord'
        },
        {
          title: '维修处理记录',
          path: '/deviceView1/reponseHome/dealRecord'
        }
      ]
    }
  },
  mounted() {
    this.addLocalStorageEventByKey('updateWorkerList', this.updateWorkerList)
    let query = this.$route.query
    let tab = 'repairResponseRecord'
    if (this.$valueIsExist(query, 'tab')) {
      tab = query['tab']
    }
    this.tabChange(`/deviceView1/reponseHome/${tab}`)
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateWorkerList)
  },
  methods: {
    updateWorkerList: function() {
      this.query['t'] = new Date().getTime()
      this.$bus.emit('searchEvent', {})
    },
    searchEvent: function(res) {
      this.query = res['query']
    },
    tabChange(e) {
      this.activeTab = e
      this.$router.replace(e).catch(e => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.resonseHome {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;

  .page-container {
    width: 100%;
    height: calc(100% - 85px);
  }

}


::v-deep .van-loading__text {
  color: white;
}

::v-deep .van-loading__circular {
  color: white;
}

::v-deep .van-pull-refresh__head {
  color: white;
}


::v-deep .van-list__finished-text {
  color: white;
}


</style>